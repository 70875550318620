import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from './en';
import { he } from './he';

const LANGUAGE_STORAGE_KEY = "LM_LANGUAGE";

export const initTranslation = (lng = 'en') => {
  const savedLanguage = getLanguage();
  const language = savedLanguage || lng;
  i18n
  .use(initReactI18next)
  .init({
    resources: { en, he },
    lng: language,
    fallbackLng: language,
    interpolation: {
      escapeValue: false
    },
    parseMissingKeyHandler: (key: string, test: any) => {
      console.log(`Translation is missing for the key ${key}`);
    }
  });
}

export const saveLanguage = (language: string) => {
  localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
}

export const getLanguage = () => {
  return localStorage.getItem(LANGUAGE_STORAGE_KEY);
}
